// 常量路由
export const constantRoutes = [
  {
    path: "/",
    redirect: "/home",
    meta: {
      hidden: true,
    },
  },
  // 原来的
  // {
  //   path: '/decoration',
  //   name: '装修',
  //   component: () => import('@/views/decoration/index.vue'),
  //   meta: {
  //     icon: 'el-icon-s-home',
  //     name: '装修',
  //     affix: true
  //   },
  // },

  {
    path: "/login",
    meta: {
      hidden: true,
    },
    component: () => import("@/views/login/index"),
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      icon: "el-icon-s-home",
      name: "首页",
      affix: true,
    },
    component: () => import("../views/home/index.vue"),
  },
];

// 任意路由
export const anyRoutes = [
  //
];

// 动态路由
export const asyncRoutes = () => [
  {
    path: "/goods",
    name: "Goods",
    redirect: "/goods/list",
    meta: {
      icon: "el-icon-s-goods",
      name: "商品管理",
    },
    component: () => import("@/views/goods/index.vue"),
    children: [
      {
        path: "/goods/list",
        name: "GoodsList",
        component: () => import("@/views/goods/goods-list/list.vue"),
        meta: {
          name: "商品列表",
          keepAlive: true,
        },
      },
      {
        path: "/goods/list/detail/:id?",
        name: "GoodsDetail",
        component: () => import("@/views/goods/goods-list/detail.vue"),
        meta: { name: "商品详情", hidden: true, activeMenu: "/goods/list" },
      },
      {
        path: "/goods/list/add",
        name: "GoodsAdd",
        component: () => import("@/views/goods/goods-list/addOrEdit.vue"),
        meta: {
          name: "创建商品",
          hidden: true,
          keepAlive: false,
          activeMenu: "/goods/list",
        },
      },
      {
        path: "/goods/list/edit/:id?",
        name: "GoodsEdit",
        component: () => import("@/views/goods/goods-list/addOrEdit.vue"),
        meta: {
          name: "编辑商品",
          hidden: true,
          keepAlive: false,
          activeMenu: "/goods/list",
        },
      },
      {
        path: "/goods/category",
        name: "GoodsCategory",
        component: () => import("@/views/goods/goods-category/list.vue"),
        meta: {
          name: "商品分类",
        },
      },
      {
        path: "/goods/category/add/:id?/:parentId?",
        name: "CategoryAdd",
        component: () => import("@/views/goods/goods-category/addOrEdit.vue"),
        meta: {
          name: "新增商品分类",
          hidden: true,
          activeMenu: "/goods/category",
        },
      },
      {
        path: "/goods/category/edit/:id?/:parentId?",
        name: "CategoryEdit",
        component: () => import("@/views/goods/goods-category/addOrEdit.vue"),
        meta: {
          name: "编辑商品分类",
          hidden: true,
          activeMenu: "/goods/category",
        },
      },
      {
        path: "/goods/inventory",
        name: "GoodsInventory",
        component: () => import("@/views/goods/goods-inventory/list.vue"),
        meta: {
          name: "库存明细",
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: "/member",
    name: "Member",
    redirect: "/member/member-list",
    component: () => import("@/views/member/index.vue"),
    meta: {
      name: "用户管理",
      icon: "el-icon-user-solid",
    },
    children: [
      {
        path: "/member/member-list",
        name: "MemberList",
        component: () => import("@/views/member/member-list/list.vue"),
        meta: {
          name: "用户列表",
          keepAlive: true,
        },
      },
      {
        path: "/member/white-list",
        name: "WhiteList",
        component: () => import("@/views/member/white-list/list.vue"),
        meta: { name: "白名单列表", keepAlive: true },
      },
      {
        path: "/member/white-list/add",
        name: "WhiteAdd",
        component: () => import("@/views/member/white-list/addOrEdit.vue"),
        meta: {
          name: "新增白名单",
          hidden: true,
          activeMenu: "/member/white-list",
        },
      },
      {
        path: "/member/white-list/edit/:id?",
        name: "WhiteEdit",
        component: () => import("@/views/member/white-list/addOrEdit.vue"),
        meta: {
          name: "编辑白名单",
          hidden: true,
          activeMenu: "/member/white-list",
        },
      },
      {
        path: "/member/black-list",
        name: "BlackList",
        component: () => import("@/views/member/black-list/list.vue"),
        meta: { name: "黑名单列表", keepAlive: true },
      },
      {
        path: "/member/black-list/add",
        name: "BlackAdd",
        component: () => import("@/views/member/black-list/addOrEdit.vue"),
        meta: {
          name: "新增黑名单",
          hidden: true,
          activeMenu: "/member/black-list",
        },
      },
      {
        path: "/member/black-list/edit/:id?",
        name: "BlackEdit",
        component: () => import("@/views/member/black-list/addOrEdit.vue"),
        meta: {
          name: "编辑黑名单",
          hidden: true,
          activeMenu: "/member/black-list",
        },
      },
      {
        path: "/member/order-remark-list",
        name: "OrderRemarkList",
        component: () => import("@/views/member/order-remark/list.vue"),
        meta: { name: "订单备注", keepAlive: true },
      },
      {
        path: "/member/afterSale-remark-list",
        name: "AfterSaleAllRemarkList",
        component: () => import("@/views/member/afterSale-remark/list.vue"),
        meta: { name: "售后备注", keepAlive: true },
      },
    ],
  },
  {
    path: "/order",
    name: "Order",
    component: () => import("@/views/order/index.vue"),
    meta: { name: "订单管理", icon: "el-icon-s-order" },
    children: [
      {
        path: "/order/order-list",
        name: "OrderList",
        component: () => import("@/views/order/order-list/list.vue"),
        meta: { name: "订单列表", keepAlive: true },
      },
      {
        path: "/order/detail/:id?",
        name: "OrderDetail",
        component: () => import("@/views/order/order-list/detail.vue"),
        meta: {
          name: "订单详情",
          hidden: true,
          keepAlive: true,
          activeMenu: "/order/order-list",
        },
      },
      {
        path: "/order/delivery/:id?",
        name: "OrderDelivery",
        component: () => import("@/views/order/order-list/delivery.vue"),
        meta: { name: "发货", hidden: true, activeMenu: "/order/order-list" },
      },
      {
        path: "/order/delivery_store/:id?",
        name: "OrderDeliveryStore",
        component: () => import("@/views/order/order-list/delivery-store.vue"),
        meta: {
          name: "门店自提",
          hidden: true,
          activeMenu: "/order/order-list",
        },
      },
      {
        path: "/order/order-bill/:id?",
        name: "OrderBill",
        component: () => import("@/views/order/order-list/bill.vue"),
        meta: { name: "账单", hidden: true, activeMenu: "/order/order-list" },
      },
      {
        path: "/order/order-list/update-goods/:id?",
        name: "OrderUpdateGoods",
        component: () =>
          import("@/views/order/order-list/modules/update-goods.vue"),
        meta: {
          name: "修改商品",
          hidden: true,
          activeMenu: "/order/order-list",
        },
      },
      {
        path: "/order/contract-detail/:id?",
        name: "OrderContractDetail",
        component: () => import("@/views/order/order-list/contract-detail.vue"),
        meta: {
          name: "合同凭证",
          hidden: true,
          activeMenu: "/order/order-list",
        },
      },
      {
        path: "/order/credit-report/:id?",
        name: "OrderCreditReport",
        component: () => import("@/views/order/order-list/credit-report.vue"),
        meta: {
          name: "征信报告",
          hidden: true,
          activeMenu: "/order/order-list",
        },
      },
      {
        path: "/order/bill-list",
        name: "BillList",
        component: () => import("@/views/order/bill-list/list.vue"),
        meta: { name: "账单列表", keepAlive: true },
      },
    ],
  },

  {
    path: "/finance",
    name: "Finance",
    component: () => import("@/views/finance/index.vue"),
    meta: { name: "财务管理", icon: "el-icon-coin" },
    children: [
      {
        path: "/finance/report",
        name: "FinanceReport",
        component: () => import("@/views/finance/report/list.vue"),
        meta: { name: "财务流水" },
      },
      {
        path: "/finance/report/add",
        name: "FinanceReportAdd",
        component: () => import("@/views/finance/report/addOrEdit.vue"),
        meta: { name: "添加流水", hidden: true, activeMenu: "/finance/report" },
      },
      {
        path: "/finance/count",
        name: "FinanceCount",
        component: () => import("@/views/finance/count/list.vue"),
        meta: { name: "账单管理" },
      },
      {
        path: "/finance/store",
        name: "FinanceStore",
        component: () => import("@/views/finance/store/settlement.vue"),
        meta: { name: "门店结算管理" },
      },

      // {
      //   path: '/finance/reduce-pay/:id?',
      //   name: 'FinanceReducePay',
      //   component: () => import('@/views/finance/count/reduce-pay.vue'),
      //   meta: { name: '费用减免', hidden: true, activeMenu: '/finance/count' }
      // },
      // {
      //   path: '/finance/offline-pay/:id?',
      //   name: 'FinanceOfflinePay',
      //   component: () => import('@/views/finance/count/offline-pay.vue'),
      //   meta: { name: '线下还款', hidden: true, activeMenu: '/finance/count' }
      // }
    ],
  },
  {
    path: "/review",
    name: "Review",
    component: () => import("@/views/review/index.vue"),
    meta: { name: "审核管理", icon: "el-icon-s-platform" },
    children: [
      {
        path: "/review/review-list",
        name: "ReviewList",
        component: () => import("@/views/review/review-list/list.vue"),
        meta: { name: "审核列表" },
      },
      {
        path: "/review/review-detail/:id?",
        name: "ReviewDetail",
        component: () => import("@/views/review/review-list/detail.vue"),
        meta: {
          name: "审核详情",
          hidden: true,
          keepAlive: true,
          activeMenu: "/review/review-list",
        },
      },
      {
        path: "/review/update-goods/:id?",
        name: "ReviewUpdateGoods",
        component: () =>
          import("@/views/review/review-list/modules/update-goods.vue"),
        meta: {
          name: "修改商品",
          hidden: true,
          activeMenu: "/review/review-list",
        },
      },
      {
        path: "/review/assign/list",
        name: "ReviewAssignList",
        component: () => import("@/views/review/review-assign/list.vue"),
        meta: { name: "审核明细", keepAlive: true },
      },
      {
        path: "/review/sys_rule/list",
        name: "RuleList",
        component: () => import("@/views/review/sys-rule/list.vue"),
        meta: { name: "规则配置", keepAlive: true },
      },
      {
        path: "/review/sys_rule/add",
        name: "RuleAdd",
        component: () => import("@/views/review/sys-rule/addOrEdit.vue"),
        meta: {
          name: "新增规则",
          hidden: true,
          activeMenu: "/review/sys_rule/list",
        },
      },
      {
        path: "/review/sys_rule/edit/:id?",
        name: "RuleEdit",
        component: () => import("@/views/review/sys-rule/addOrEdit.vue"),
        meta: {
          name: "编辑规则",
          hidden: true,
          activeMenu: "/review/sys_rule/list",
        },
      },
      {
        path: "/review/sys_rule/detail/:id?",
        name: "RuleDetail",
        component: () => import("@/views/review/sys-rule/detail.vue"),
        meta: {
          name: "详情",
          hidden: true,
          activeMenu: "/review/sys_rule/list",
        },
      },
    ],
  },
  {
    path: "/afterSale",
    name: "AfterSale",
    component: () => import("@/views/afterSale/index.vue"),
    meta: { name: "售后管理", icon: "el-icon-s-check" },
    children: [
      {
        path: "/afterSale/list",
        name: "AfterSaleList",
        component: () => import("@/views/afterSale/afterSale-list/list.vue"),
        meta: { name: "售后列表", keepAlive: true },
      },
      {
        path: "/afterSale/afterSale-bill/:id?",
        name: "AfterSaleBill",
        component: () => import("@/views/afterSale/afterSale-list/bill.vue"),
        meta: { name: "账单", hidden: true, activeMenu: "/afterSale/list" },
      },
      {
        path: "/afterSale/remark",
        name: "AfterSaleRemark",
        component: () => import("@/views/afterSale/afterSale-list/remark.vue"),
        meta: { name: "跟进记录", hidden: true, activeMenu: "/afterSale/list" },
      },
      {
        path: "/afterSale/detail/:id?",
        name: "AfterSaleDetail",
        component: () => import("@/views/afterSale/afterSale-list/detail.vue"),
        meta: { name: "详情", hidden: true, activeMenu: "/afterSale/list" },
      },
      {
        path: "/afterSale/contract-detail/:id?",
        name: "AfterSaleContractDetail",
        component: () =>
          import("@/views/afterSale/afterSale-list/contract-detail.vue"),
        meta: { name: "合同凭证", hidden: true, activeMenu: "/afterSale/list" },
      },
      {
        path: "/afterSale/credit-report/:id?",
        name: "AfterSaleCreditReport",
        component: () =>
          import("@/views/afterSale/afterSale-list/credit-report.vue"),
        meta: { name: "征信报告", hidden: true, activeMenu: "/afterSale/list" },
      },
      {
        path: "/afterSale/assign/list",
        name: "AfterSaleAssignList",
        component: () => import("@/views//afterSale/afterSale-assign/list.vue"),
        meta: { name: "售后明细", keepAlive: true },
      },
    ],
  },
  {
    path: "/store",
    name: "Store",
    component: () => import("@/views/store/index.vue"),
    meta: { name: "门店管理", icon: "el-icon-shopping-cart-2" },
    children: [
      {
        path: "/store/list",
        name: "StoreList",
        component: () => import("@/views/store/store-list/list.vue"),
        meta: { name: "门店列表" },
      },
      {
        path: "/store/add",
        name: "StoreAdd",
        component: () => import("@/views/store/store-list/addOrEdit.vue"),
        meta: { name: "新增门店", hidden: true, activeMenu: "/store/list" },
      },
      {
        path: "/store/edit/:id?",
        name: "StoreEdit",
        component: () => import("@/views/store/store-list/addOrEdit.vue"),
        meta: { name: "编辑门店", hidden: true, activeMenu: "/store/list" },
      },
      {
        path: "/store/goods1/list",
        name: "StoreGoods1List",
        component: () => import("@/views/store/store-goods/list1.vue"),
        meta: { name: "3C商品" },
      },
      {
        path: "/store/goods1/add",
        name: "StoreGoods1Add",
        component: () => import("@/views/store/store-goods/addOrEdit1.vue"),
        meta: {
          name: "新增3C商品",
          hidden: true,
          activeMenu: "/store/goods1/list",
        },
      },
      {
        path: "/store/goods1/edit/:id?",
        name: "StoreGoods1Edit",
        component: () => import("@/views/store/store-goods/addOrEdit1.vue"),
        meta: {
          name: "编辑3C商品",
          hidden: true,
          activeMenu: "/store/goods1/list",
        },
      },
      {
        path: "/store/goods2/list",
        name: "StoreGoods2List",
        component: () => import("@/views/store/store-goods/list2.vue"),
        meta: { name: "电车商品" },
      },
      {
        path: "/store/goods2/add",
        name: "StoreGoods2Add",
        component: () => import("@/views/store/store-goods/addOrEdit2.vue"),
        meta: {
          name: "新增电车商品",
          hidden: true,
          activeMenu: "/store/goods2/list",
        },
      },
      {
        path: "/store/goods2/edit/:id?",
        name: "StoreGoods2Edit",
        component: () => import("@/views/store/store-goods/addOrEdit2.vue"),
        meta: {
          name: "编辑电车商品",
          hidden: true,
          activeMenu: "/store/goods2/list",
        },
      },
      {
        path: "/store/calculate",
        name: "StoreCalculate",
        component: () => import("@/views/store/calculate/index.vue"),
        meta: { name: "方案计算器" },
      },
      {
        path: "/store/combo/list",
        name: "StoreComboList",
        component: () => import("@/views/store/combo/list.vue"),
        meta: { name: "门店方案管理" },
      },
    ],
  },
  {
    path: "/merchant",
    name: "Merchant",
    redirect: "/merchant/merchant-list",
    component: () => import("@/views/merchant/index.vue"),
    meta: { name: "商户管理", icon: "el-icon-s-shop" },
    children: [
      {
        path: "/merchant/merchant-list",
        name: "MerchantList",
        component: () => import("@/views/merchant/merchant-list/list.vue"),
        meta: { name: "商户列表" },
      },
      {
        path: "/merchant/merchant-list/add",
        name: "MerchantAdd",
        component: () => import("@/views/merchant/merchant-list/addOrEdit.vue"),
        meta: {
          name: "新增商户",
          hidden: true,
          activeMenu: "/merchant/merchant-list",
        },
      },
      {
        path: "/merchant/merchant-list/edit/:id?",
        name: "MerchantEdit",
        component: () => import("@/views/merchant/merchant-list/addOrEdit.vue"),
        meta: {
          name: "编辑商户",
          hidden: true,
          activeMenu: "/merchant/merchant-list",
        },
      },
      {
        path: "/merchant/merchant-list/accp_detail/:id?",
        name: "MerchantAccpDetail",
        component: () =>
          import("@/views/merchant/merchant-list/accp-detail.vue"),
        meta: {
          name: "提现",
          hidden: true,
          activeMenu: "/merchant/merchant-list",
        },
      },
      {
        path: "/merchant/withdraw/list",
        name: "MerchantWithdrawList",
        component: () => import("@/views/merchant/withdraw-list/list.vue"),
        meta: { name: "提现列表" },
      },
    ],
  },
  // 资方管理
  {
    path: "/capital",
    name: "Capital",
    component: () => import("@/views/capital/index.vue"),
    meta: { name: "资方管理", icon: "el-icon-s-custom" },
    children: [
      {
        path: "/capital/capital-list/list",
        name: "CapitalList",
        component: () => import("@/views/capital/capital-list/list.vue"),
        meta: { name: "资方列表" },
      },
      {
        path: "/capital/capital-bill/list",
        name: "CapitalBillList",
        component: () => import("@/views/capital/capital-bill/list.vue"),
        meta: { name: "动账明细" },
      },
      {
        path: "/capital/order-list/list",
        name: "CapitalOrderList",
        component: () => import("@/views/capital/order-list/list.vue"),
        meta: { name: "订单列表" },
      },
      {
        path: "/capital/order-list/list2",
        name: "CapitalOrderList2",
        component: () => import("@/views/capital/order-list/list2.vue"),
        meta: { name: "订单列表（新）" },
      },
      {
        path: "/capital/capital-withdraw/list",
        name: "CapitalWithdrawList",
        component: () => import("@/views/capital/capital-withdraw/list.vue"),
        meta: { name: "资方提现" },
      },
    ],
  },
  {
    path: "/channel",
    name: "Channel",
    redirect: "/channel/channel-list",
    component: () => import("@/views/channel/index.vue"),
    meta: { name: "渠道管理", icon: "el-icon-message-solid" },
    children: [
      {
        path: "/channel/channel-list",
        name: "ChannelList",
        component: () => import("@/views/channel/channel-list/list.vue"),
        meta: { name: "渠道列表" },
      },
      {
        path: "/channel/channel-list/add",
        name: "ChannelAdd",
        component: () => import("@/views/channel/channel-list/addOrEdit.vue"),
        meta: {
          name: "新增渠道",
          hidden: true,
          activeMenu: "/channel/channel-list",
        },
      },
      {
        path: "/channel/channel-list/edit/:id?",
        name: "ChannelEdit",
        component: () => import("@/views/channel/channel-list/addOrEdit.vue"),
        meta: {
          name: "编辑渠道",
          hidden: true,
          activeMenu: "/channel/channel-list",
        },
      },
      {
        path: "/channel/channel-order",
        name: "ChannelOrder",
        component: () => import("@/views/channel/channel-order/list.vue"),
        meta: { name: "渠道订单" },
      },
      {
        path: "/channel/channel-count",
        name: "ChannelCount",
        component: () => import("@/views/channel/channel-count/list.vue"),
        meta: { name: "渠道统计" },
      },
    ],
  },
  {
    path: "/operation",
    name: "Operation",
    component: () => import("@/views/operation/index.vue"),
    meta: { name: "运营管理", icon: "el-icon-s-finance" },
    children: [
      {
        path: "/operation/ad",
        name: "Ad",
        component: () => import("@/views/operation/ad/list.vue"),
        meta: { name: "广告设置" },
      },
      {
        path: "/decoration/:page",
        name: "PageDiy",
        component: () => import("@/views/decoratePage/page/index.vue"),
        meta: {
          name: "页面装修",
          affix: true,
          realPath: "/decoration/index",
        },
      },
      {
        path: "/operation/lease",
        name: "Lease",
        component: () => import("@/views/operation/lease/list.vue"),
        meta: { name: "运营文案" },
      },
      {
        path: "/operation/contract",
        name: "Contract",
        component: () => import("@/views/operation/contract/list.vue"),
        meta: { name: "协议合同" },
      },
      {
        path: "/operation/bankCard/list",
        name: "BankCardList",
        component: () => import("@/views/operation/bank-card/list.vue"),
        meta: { name: "银行卡列表" },
      },
      {
        path: "/operation/bankCard/add",
        name: "BankCardAdd",
        component: () => import("@/views/operation/bank-card/addOrEdit.vue"),
        meta: {
          name: "添加银行卡",
          hidden: true,
          activeMenu: "/operation/bankCard/list",
        },
      },
      {
        path: "/operation/bankCard/edit/:id?",
        name: "BankCardEdit",
        component: () => import("@/views/operation/bank-card/addOrEdit.vue"),
        meta: {
          name: "编辑银行卡",
          hidden: true,
          activeMenu: "/operation/bankCard/list",
        },
      },
    ],
  },
  {
    path: "/order-netsale",
    name: "OrderNetSale",
    component: () => import("@/views/orderNetSale/index.vue"),
    meta: { name: "网销订单", icon: "el-icon-s-marketing" },
    children: [
      {
        path: "/order-netsale/order-list",
        name: "OrderNetSaleList",
        component: () => import("@/views/orderNetSale/order-list/list.vue"),
        meta: { name: "订单列表", keepAlive: true },
      },
      // {
      //   path: '/order-netsale/detail/:id?',
      //   name: 'OrderHistoryDetail',
      //   component: () => import('@/views/orderNetSale/order-list/detail.vue'),
      //   meta: { name: '详情', hidden: true, keepAlive: true, activeMenu: '/orderNetSale/order-list' }
      // },
      // {
      //   path: '/order-netsale/delivery/:id?',
      //   name: 'OrderNetSaleDelivery',
      //   component: () => import('@/views/orderNetSale/order-list/delivery.vue'),
      //   meta: { name: '发货', hidden: true, activeMenu: '/orderNetSale/order-list' }
      // },
      // {
      //   path: '/order-netsale/delivery_store/:id?',
      //   name: 'OrderNetSaleDeliveryStore',
      //   component: () => import('@/views/orderNetSale/order-list/delivery-store.vue'),
      //   meta: { name: '门店自提', hidden: true, activeMenu: '/orderNetSale/order-list' }
      // },
      // {
      //   path: '/order-netsale/order-bill/:id?',
      //   name: 'OrderNetSaleBill',
      //   component: () => import('@/views/orderNetSale/order-list/bill.vue'),
      //   meta: { name: '账单', hidden: true, activeMenu: '/orderNetSale/order-list' }
      // },
      // {
      //   path: '/order-netsale/order-list/update-goods/:id?',
      //   name: 'OrderNetSaleUpdateGoods',
      //   component: () => import('@/views/orderNetSale/order-list/modules/update-goods.vue'),
      //   meta: { name: '修改商品', hidden: true, activeMenu: '/orderNetSale/order-list' }
      // },
      // {
      //   path: '/order-netsale/contract-detail/:id?',
      //   name: 'OrderNetSaleContractDetail',
      //   component: () => import('@/views/orderNetSale/order-list/contract-detail.vue'),
      //   meta: { name: '合同凭证', hidden: true, activeMenu: '/orderNetSale/order-list' }
      // },
      // {
      //   path: '/order-netsale/credit-report/:id?',
      //   name: 'OrderNetSaleCreditReport',
      //   component: () => import('@/views/orderNetSale/order-list/credit-report.vue'),
      //   meta: { name: '征信报告', hidden: true, activeMenu: '/orderNetSale/order-list' }
      // }
    ],
  },
  {
    path: "/systemManage",
    name: "SystemManage",
    redirect: "/systemManage/dictManage/list",
    component: () => import("@/views/systemManage/index.vue"),
    meta: { name: "系统设置", icon: "el-icon-setting" },
    children: [
      {
        path: "/systemManage/dictManage/list",
        name: "DictManageList",
        component: () => import("@/views/systemManage/dictManage/index.vue"),
        meta: { name: "字典管理" },
      },
      {
        path: "/systemManage/dictManage/addOrEdit",
        name: "dictManageAddOrEdit",
        component: () =>
          import("@/views/systemManage/dictManage/detail-dict.vue"),
        meta: {
          hidden: true,
          name: "字典管理",
          activeMenu: "/systemManage/dictManage/list",
        },
      },
      {
        path: "/systemManage/dictValueManage",
        name: "DictValueManage",
        component: () =>
          import("@/views/systemManage/dictManage/list-dict-value.vue"),
        meta: {
          hidden: true,
          name: "字典值管理",
          activeMenu: "/systemManage/dictManage/list",
        },
      },
      {
        path: "/systemManage/dictValueManage/addOrEdit",
        name: "DictValueAddOrEdit",
        component: () =>
          import("@/views/systemManage/dictManage/detail-dict-value.vue"),
        meta: {
          hidden: true,
          name: "字典值管理",
          activeMenu: "/systemManage/dictManage/list",
        },
      },
      // {
      //   path: '/systemManage/dataSync/list',
      //   name: 'DataSyncList',
      //   component: () => import('@/views/systemManage/dataSync/list.vue'),
      //   meta: { name: '数据同步' }
      // },
      // {
      //   path: '/systemManage/config/list',
      //   name: 'ConfigList',
      //   component: () => import('@/views/systemManage/config/list.vue'),
      //   meta: { name: '开发设置' }
      // },
      {
        path: "/systemManage/logManage/list",
        name: "LogManageList",
        component: () => import("@/views/systemManage/logManage/list.vue"),
        meta: { name: "操作日志" },
      },
    ],
  },
  {
    path: "/powerManage",
    name: "PowerManage",
    redirect: "/powerManage/user/list",
    component: () => import("@/views/powerManage/index.vue"),
    meta: { name: "权限管理", icon: "el-icon-s-check" },
    children: [
      {
        path: "/powerManage/user/list",
        name: "UserList",
        component: () => import("@/views/powerManage/user/list.vue"),
        meta: { name: "管理员" },
      },
      {
        path: "/powerManage/user/add",
        name: "UserAdd",
        component: () => import("@/views/powerManage/user/addEdit.vue"),
        meta: {
          name: "新增管理员",
          hidden: true,
          activeMenu: "/powerManage/user/list",
        },
      },
      {
        path: "/powerManage/user/edit/:id?",
        name: "UserEdit",
        component: () => import("@/views/powerManage/user/addEdit.vue"),
        meta: {
          name: "编辑管理员",
          hidden: true,
          activeMenu: "/powerManage/user/list",
        },
      },
      {
        path: "/powerManage/role/list",
        name: "RoleList",
        component: () => import("@/views/powerManage/role/list.vue"),
        meta: { name: "角色管理" },
      },
      {
        path: "/powerManage/role/addEdit",
        name: "RoleAdd",
        component: () => import("@/views/powerManage/role/addEdit.vue"),
        meta: {
          name: "新增",
          hidden: true,
          activeMenu: "/powerManage/role/list",
        },
      },
      {
        path: "/powerManage/role/addEdit/:id?",
        name: "RoleEdit",
        component: () => import("@/views/powerManage/role/addEdit.vue"),
        meta: {
          name: "编辑",
          hidden: true,
          activeMenu: "/powerManage/role/list",
        },
      },
      {
        path: "/powerManage/menu/list",
        name: "MenuList",
        component: () => import("@/views/powerManage/menu/list.vue"),
        meta: { name: "菜单管理", keepAlive: true },
      },
      {
        path: "/powerManage/menu/addEdit/:menuId?/:parentId?",
        name: "MenuAdd",
        component: () => import("@/views/powerManage/menu/addEdit.vue"),
        meta: {
          name: "新增",
          hidden: true,
          activeMenu: "/powerManage/menu/list",
        },
      },
      {
        path: "/powerManage/menu/addEdit/:menuId?/:parentId?",
        name: "MenuEdit",
        component: () => import("@/views/powerManage/menu/addEdit.vue"),
        meta: {
          name: "编辑",
          hidden: true,
          activeMenu: "/powerManage/menu/list",
        },
      },
      {
        path: "/powerManage/dept/list",
        name: "Dept",
        component: () => import("@/views/powerManage/dept/list.vue"),
        meta: { name: "部门管理" },
      },
      {
        path: "/powerManage/job/list",
        name: "JobList",
        component: () => import("@/views/powerManage/job/list.vue"),
        meta: { name: "岗位管理" },
      },
      {
        path: "/powerManage/job/add",
        name: "JobAdd",
        component: () => import("@/views/powerManage/job/addOrEdit.vue"),
        meta: {
          name: "新增岗位",
          hidden: true,
          activeMenu: "/powerManage/job/list",
        },
      },
      {
        path: "/powerManage/job/edit/:id?",
        name: "JobEdit",
        component: () => import("@/views/powerManage/job/addOrEdit.vue"),
        meta: {
          name: "编辑岗位",
          hidden: true,
          activeMenu: "/powerManage/job/list",
        },
      },
    ],
  },
  {
    path: "/stats",
    name: "Stats",
    component: () => import("@/views/stats/index.vue"),
    meta: { name: "数据统计", icon: "el-icon-s-data" },
    children: [
      {
        path: "/stats/fundsDay/list",
        name: "FundsDayList",
        component: () => import("@/views/stats/fundsDay-list/list.vue"),
        meta: { name: "本金回收分析(日)" },
      },
      {
        path: "/stats/fundsMonth/list",
        name: "FundsMonthList",
        component: () => import("@/views/stats/fundsMonth-list/list.vue"),
        meta: { name: "本金回收分析(月)" },
      },
    ],
  },
];
